import {useEffect, useState} from 'react'
import { Grid, Typography, Card, CardContent, CardMedia, Menu, MenuItem } from '@mui/material';
import { useListDeviceQuery, useGetEventQuery } from '../../store/cirstm/cirstm.api'

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { setDevice, setDeviceEvent } from '../../store/locomotive/locomotiveSlice'
import YandexMap from './YandexMap';
import { timestampToString } from '../../utils/utils';
import { Stack } from '@mui/system';


const LocomotiveInfoFrame = () => {
    const [timeString, setTimeString] = useState('');
    const { isLoading, isError, data: devices } = useListDeviceQuery();
    const {device, event: deviceEvent} = useAppSelector((state) => state.locomotiveReducer)
    // const { isLoading: isEventLoading, isFetching, isError: isEventError, data: deviceEvent } = useGetEventQuery(device?.id || '', {pollingInterval: 2000});
    const { isLoading: isEventLoading, isFetching, isError: isEventError, data: deviceCurrentEvent } = 
        useGetEventQuery(device?.text_id || '',
             {refetchOnMountOrArgChange: true,
                 skip: device?.text_id == null,
                  pollingInterval: 2000});

    const dispatch = useAppDispatch();
    

    const handleChange = (event: SelectChangeEvent) => {
        const selected = devices?.find((value, index, obj) => {
            return value.text_id === event.target.value
        })
        dispatch(setDevice(selected ? selected : null))
    };

    useEffect(() => {
        if(!isLoading && devices)
            dispatch(setDevice(devices[0]))
    }, [isLoading])

    useEffect(() => {
        if (!isFetching){
            if (!isEventError && deviceCurrentEvent !== undefined)
                dispatch(setDeviceEvent(deviceCurrentEvent))
            else
                dispatch(setDeviceEvent(null))
        }
    }, [isFetching, isEventError])

    useEffect(() => {
         setTimeString('Последнее обновление: ' + timestampToString(deviceEvent?.timestamp, 'DD.MM.YYYY HH:mm:ss')) 
        }, [deviceEvent])
    
    return ( 
    <Grid item xs={12} sm={4} md={3} minWidth={200}>
        <Card variant='outlined'>
            <CardContent>
                    <Stack direction={'row'} sx={{maxWidth: '300px', alignItems: 'center'}}>
                        <FormControl sx={{ m: 1, minWidth: 120, width: '90%'}}>
                        <Select
                                value={device?.text_id || ''}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                                {devices?.map(d => <MenuItem value={d.text_id} key={d.text_id}>{d.visible_name}</MenuItem>)}
                        </Select>
                        </FormControl>
                        <CardMedia
                        component="img"
                        sx={{maxHeight: '56px'}}
                        image={
                            device?.visible_name.includes('ТЭМГ')
                                ? '/images/temg1.jpg'
                                : device?.visible_name.includes('ТЭМ10') ? '/images/tem10.jpg'  : '/images/tgm8a.jpg'
                        }
                            alt={device?.type_name}
                        />
                    </Stack>
                    

                    {!deviceEvent ? (
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            Нет данных
                        </Typography>
                    ) : (
                        <>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {timeString}
                            </Typography>
                            <Card>
                                <YandexMap
                                    latitude={deviceEvent.latitude}
                                    longitude={deviceEvent.longitude}
                            /> 
                            </Card>
                             
                        </>
                    )}
            </CardContent>      
        </Card>
    </Grid>
    );
}

export default LocomotiveInfoFrame;