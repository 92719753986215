import React from 'react'
import Navigation from './navigation/Navigation'
import User from './user/User'


const Header = () => {
  return (
    <div className='flex rounded-sm h-12 drop-shadow-md items-center justify-between p-3 bg-primary'>
        <Navigation/>
        <User/>
    </div>
  )
}

export default Header