import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Navigation.module.scss'
import { privateRoutes } from '../../../../router/AppRouter'
import { Typography } from '@mui/material'

export default function Navigation() {
  return (
    <ul className={styles.ul}>
          {privateRoutes.map(route => 
            <li key={route.path}>
                <Link to={route.path}>
                    {route.label}
                  </Link>
            </li>)
          }
    </ul>
  )
}
