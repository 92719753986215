import React, { useEffect } from 'react';
import {YMaps, Map, Placemark} from 'react-yandex-maps';

export interface YandexMapsProps{
    latitude: number,
    longitude: number
}
const YandexMap = (props: YandexMapsProps) => {
    useEffect(() => {}, [props])
    return (
        <>
        {props.latitude && props.longitude ?
            <YMaps>
                <Map state={{ center: [props.latitude, props.longitude], zoom: 15 }}>
                    <Placemark geometry={[props.latitude, props.longitude]} />
                </Map>
            </YMaps>
            : <></>
        }
        </>
        
    );
}

export default YandexMap;