import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '..'
import { logout, setUser } from '../user/userSlice'
import { Mutex } from 'async-mutex'

const DEBUG_URL = 'http://localhost:8000/v1/'
const PROD_URL = 'https://api.cir-stm.ru/v1/' 
const apiUrl = process.env.NODE_ENV === 'production' ? PROD_URL : DEBUG_URL

export const baseQuery = fetchBaseQuery({
    baseUrl: apiUrl,
    credentials: 'include', //Авторизация происходит по token из cookie
    mode: 'cors'
})


interface RefreshResult{
    refresh_token?: string;
    access_token?: string;
}

//Мьютекс необходим для защиты от множественных refresh запросов, т.к. refresh_token инвалидируется после первого применения.
const mutex = new Mutex()

//При ошибке авторизации попытается использовать refresh token текущего пользователя и повторит запрос. В случае неудачи разлогинит пользователя
//Должен использоваться в качестве baseQuery во всех api
export const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)

    if (result?.error?.status === 401) {
        if(!mutex.isLocked()){
            const release = await mutex.acquire()
            try{
                // send refresh token to get new access token 
                const refresh_token = (api.getState() as RootState).userReducer.user?.refresh_token

                const refreshResult = await baseQuery({url: 'authentication/refresh/', method: 'POST', body: {'refresh_token': refresh_token}}, api, extraOptions)
                const refreshData: RefreshResult  = refreshResult?.data as RefreshResult
                if (refreshData.refresh_token) {
                    const user = (api.getState() as RootState).userReducer.user
                    // store the new token 
                    if(user)
                        api.dispatch(setUser({...user, refresh_token: refreshData.refresh_token}))
                    // retry the original query with new access token 
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    api.dispatch(logout())
                }
            } finally {
                release()
            }
        } else {
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }else if(result?.error){
        console.log('base query error', result.error);
        
    }

    return result
}