import { Card, Button, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download'
import ContentCopy from '@mui/icons-material/ContentCopy'
import { useState } from 'react';
import { ITelemetryRecord } from '../../models/models';
import { useLazyGetDownloadLinkQuery } from '../../store/cirstm/cirstm.api';

const sizeToStr = (size: number) => {
    if (Math.abs(size) < 1024) {
        return size + ' B';
    }
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    let u = -1;
    const r = 10;

    do {
        size /= 1024;
        ++u;
    } while (Math.round(Math.abs(size) * r) / r >= 1024 && u < units.length - 1);

    return size.toFixed(1) + ' ' + units[u];
}

type ItemProps = {
    record: ITelemetryRecord
}

const TelemetryItem = ({ record }: ItemProps) => {
    const [downloadLink, setDownloadLink] = useState('');
    const [trigger] = useLazyGetDownloadLinkQuery();

    const fetchLink = (callback: any) => {
        if (record.key) {
            trigger(record.key).then(
                (response: any) =>{ 
                    const link = response?.data?.link
                    if(link){
                        setDownloadLink(link)
                        callback(link)
                    }
                    return
                })
            
            // fetch('/telemetry/get-download-link?key=' + record.key)
            //     .then((response) =>
            //         response.json())
            //     .then((data) => {
            //         setDownloadLink(data.link)
            //         callback(data.link)
            //     });
        }
    }

    const openLink = (link: string) => {
        window.open(link, "_self")
    }
    const copyLink = (link: string) => {
        navigator.clipboard.writeText(link)
    }
    return (
        <Card sx={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button href={downloadLink} onClick={() => {
                    if (downloadLink === '')
                        fetchLink(openLink);
                }
                }>
                    <DownloadIcon />
                </Button>
                <Button onClick={() => {
                    if (downloadLink === '')
                        fetchLink(copyLink);
                    else
                        copyLink(downloadLink)
                }
                }>
                    <ContentCopy />
                </Button>
                <Typography sx={{ fontSize: 14 }}>
                    {record.name}
                </Typography>
            </div>
            <Typography style={{ position: 'relative', bottom: '5px', marginRight: '5px', textAlign: 'right' }} sx={{marginLeft: 'auto', fontSize: 12, alignSelf: 'end' }} color="text.secondary">
                {sizeToStr(record.size)}
            </Typography>
        </Card>
    );
}

export default TelemetryItem;