import React, { useEffect } from 'react';
import AppRouter from './router/AppRouter';
import { useRefreshTokenMutation } from './store/cirstm/auth.api'
import {dataToUser} from './utils/utils'
import { useAppDispatch } from './hooks/redux';
import { setUser } from './store/user/userSlice'


function App() {

  const [refreshToken, { isLoading, isError, error, isSuccess, data }] = useRefreshTokenMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    //const refresh_token = localStorage.getItem('refresh_token')
    //if(refresh_token)
      //refreshToken({'refresh_token': refresh_token})
    // eslint-disable-next-line react-hooks/exhaustive-deps

    //fetch('http://localhost:8000/blockreg_v2/get_blocks_list').then(r => console.log(r)).catch(e => console.log(e));
    
    
  }, [])

  // useEffect(() => {
  //   if (isSuccess) {
  //     const user = dataToUser(data);
  //     dispatch(setUser(user))
  //   } else if (isError)
  //     console.log('error')
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  return (
    
      <AppRouter/>
  );
}

export default App;
