import React from 'react'
import { FaUserCircle } from 'react-icons/fa'
import style from './User.module.scss'
import { useAppSelector, useAppDispatch} from '../../../../hooks/redux';
import { logout } from '../../../../store/user/userSlice'
import LetterAvatar from '../../../ui/LetterAvatar'

export default function User() {
  const { user } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();

  return (
    <div className={style.user}>
        {/* <h1>{user?.name}</h1>
        <FaUserCircle className='text-2xl text-white hover:cursor-pointer' onClick={()=>{dispatch(logout())}}/> */}
      <LetterAvatar name={user?.username} className='text-2xl text-white hover:cursor-pointer hover:opacity-90' onClick={() => { dispatch(logout()) }}></LetterAvatar>
    </div>
  )
}
