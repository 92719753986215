import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { IParam } from '../../models/models';

export interface SignalsTableProps{
    data?: IParam[]
}

export default function SignalsTable(props: SignalsTableProps) {
    return (
        <>
            {props.data ?
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Сигнал</TableCell>
                                <TableCell align="left">Значение</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.data.map((row: IParam) => (
                                <TableRow
                                    key={row.title}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell align="left">
                                        {(row.value == null ? '---' : row.value) +
                                            (row.value == null ? '' : " " + row.scale)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                : <></>
            }
        </>

    );
}