import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Stack } from '@mui/material';
import TelemetryItem from './TelemetryItem';
import { ITelemetryRecord } from '../../models/models';
import { useAppSelector } from '../../hooks/redux';
import { useLazyGetCurrentTelemetryQuery } from '../../store/cirstm/cirstm.api';

const CurrentSessionItem = () => {
    const { device } = useAppSelector((state: any) => state.locomotiveReducer)
    const [isChecking, setIsChecking] = useState(false)
    const [sessionData, setSessionData] = useState<ITelemetryRecord>()
    const [trigger, result] = useLazyGetCurrentTelemetryQuery()

    useEffect(() => {
        setSessionData(undefined) //Сброс значений при смене выбранного тепловоза
    }, [device])

    const fetchCurrentSession = () => {
        setIsChecking(true)
        trigger(device.text_id)
    }

    useEffect(()=> {
        if(result.isFetching || result.isLoading){
            return;
        }
        if(result.isError){
            setSessionData(undefined);
            setIsChecking(false);
            return;
        }

        let code = result?.data?.code
        if(code === 200){
            setSessionData({ 'device_id': '',
                            'key': result.data?.session_key,
                            'name': result.data?.session_name,
                            'size': result.data?.session_size })
            setIsChecking(false)
        }else if(code === 202){
            setTimeout(fetchCurrentSession, 1000)
        }
    },[result])

    return (
        <div>
            {sessionData == null ?
                <Stack direction='row' justifyContent='center' spacing={2}>
                    <Button variant='contained' disabled={isChecking} onClick={() => fetchCurrentSession()}>
                        Текущая сессия
                    </Button>
                    {isChecking && (<CircularProgress />)}
                </Stack>
                :
                <TelemetryItem record={sessionData}/>
            }
        </div>
    );
}

export default CurrentSessionItem;