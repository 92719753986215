import { IUser } from '../models/models';

const randomUUID = typeof crypto !== 'undefined' && crypto.randomUUID && crypto.randomUUID.bind(crypto);

export const getUserDevice = () => {
    let uuid = localStorage.getItem('uuid')
    if(!uuid){
        if(randomUUID){
            uuid = randomUUID()
            localStorage.setItem('uuid', uuid)
        }else
            uuid = 'undefined'
    }
    return uuid
}

export const dataToUser = (data: any) => {
    if (!data)
        return null;
    try {
        //const decoded = JSON.parse(window.atob(data.access_token.split('.')[1]))
        //const user: IUser = { name: data['user']['username'], token: {access_token: data['access_token'], refresh_token: data['refresh_token']} }
        const user: IUser = { refresh_token: data['refresh_token'], ...data['user'] }
        return user
    } catch (e) {
        console.log(e)
        return null
    }
}

export const timestampToString = (timestamp: number | undefined, format: string): string => {
    if (!timestamp || timestamp === 0) return '';
    var moment = require('moment');
    return (
        moment(timestamp).format(format)
    );
};

export const secondsToString = (timestamp: number | undefined, format: string): string  => {
    if (!timestamp) return '';
    return timestampToString(timestamp * 1000, format)
}

export const makeFieldUpdater = (fieldName: string, targetObj: React.MutableRefObject<any>, onUpdateCallback: any) : (value: string) => void => {
    const fieldUpdater = (value: string) => {
        targetObj.current[fieldName] = value
        onUpdateCallback(fieldName)
    };
    return fieldUpdater
}