import { useState, useEffect } from 'react';
import { Route, Navigate, createRoutesFromElements, createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { useAppSelector } from "../hooks/redux";
import LoginPage from "../pages/LoginPage";
import LocomotiveStatusPage from '../pages/LocomotiveStatusPage';
import DownloadsPage from '../pages/DownloadsPage';
import BlocksPage from '../pages/BlocksPage';
import ErrorPage from '../pages/ErrorPage';
import Layout from '../components/layout/Layout';
import BlockCard from '../components/block-status/BlockCard';
import ModuleCard from '../components/block-status/ModuleCard';
import AddBlock from '../components/block-status/new-block/AddBlock';

enum RouteNames {
    LOGIN = '/login',
    STATES = '/states',
    BLOCKS = '/blocks',
    DOWNLOADS = '/downloads',
}

export const privateRoutes = [
    {
        path: RouteNames.STATES,
        label: 'Устройства'
    },
    // {
    //     path: RouteNames.BLOCKS,
    //     label: 'Блоки'
    // },
    // {
    //     path: RouteNames.DOWNLOADS,
    //     label: 'Загрузки'
    // },
]

const useAuth = () => {
    const { user } = useAppSelector((state) => state.userReducer);
    const [isAuth, setIsAuth] = useState(user != null);

    useEffect(() => {
        setIsAuth(user != null)
    }, [user])

    return isAuth
}

const ProtectedRoutes = (props: any) => {
    const isAuth = useAuth()

    return isAuth ? <Layout><Outlet /></Layout> : <Navigate to='/login' />
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<Outlet />} errorElement={<ErrorPage />}>
            <Route element={<ProtectedRoutes />}> {/* См. Layout Routes в документации для разделения доступа*/}
                <Route path={RouteNames.STATES} element={<LocomotiveStatusPage />} />
                {/* <Route path={RouteNames.BLOCKS} element={<BlocksPage />} />
                <Route path={RouteNames.BLOCKS + '/block/:id'} element={<BlockCard />} />
                <Route path={RouteNames.BLOCKS + '/module/:id'} element={<ModuleCard />} />
                <Route path={RouteNames.BLOCKS + '/add_block'} element={<AddBlock />} />
                <Route path={RouteNames.DOWNLOADS} element={<DownloadsPage />} /> */}
                <Route path='/' element={<Navigate to={'/states'} replace={true} />} />
            </Route>
            <Route path={RouteNames.LOGIN} element={<LoginPage />} />
        </Route>


    )
)

const AppRouter = () => {
    return (
        <RouterProvider router={router} />
    )
};

export default AppRouter;