import { createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale'

export const theme = createTheme (
  {
    palette: {
      type: 'light',
      primary: {
        main: '#e65100',
        light: '#EB7333',
        dark: '#A13800',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#536dfe',
        light: '#758AFE',
        dark: '#3A4CB1',
        contrastText: '#FFFFFF'
      },
    }
  },
  ruRU)