import React from 'react';
import { Stack, Button, Typography } from '@mui/material';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

type PaginationProps = {
    totalPages: number;
    page: number;
    changePage(p: number): void;
    hasNext: boolean;
    hasPrev: boolean
}

const Pagination = ({ totalPages, page, changePage, hasNext, hasPrev }: PaginationProps) => {
    return (
        <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
            {/* <Button
                onClick={() => {
                    changePage(1);
                }}
            >
                <FirstPageIcon fontSize='small'/>
            </Button> */}
            <Button
                disabled={!hasPrev}
                onClick={() => {
                    if (page > 1) changePage(page - 1);
                }}
            >
                <ArrowBackIosIcon />
            </Button>
            <Typography noWrap={true}>
                {' '}
                {page} из {totalPages}{' '}
            </Typography>
            <Button
                disabled={!hasNext}
                onClick={() => {
                    if (page < totalPages) changePage(page + 1);
                }}
            >
                <ArrowForwardIosIcon />
            </Button>
            {/* <Button
                onClick={() => {
                    changePage(totalPages);
                }}
            >
                <LastPageIcon />
            </Button> */}
        </Stack>
    );
};

export default Pagination;
