import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import SignalsTable from './SignalsTable';
import { IParam } from '../../models/models';
import { useAppSelector } from '../../hooks/redux';


const LocomotiveSignalsFrame = () => {
    const [tableValues, setTableValues] = useState<IParam[]>();
    const { device, event: deviceEvent } = useAppSelector((state) => state.locomotiveReducer)


    useEffect(()=>{
        setTableValues(deviceEvent?.params)   
    }, [deviceEvent])

    return (
        <Card variant="outlined">
            <CardHeader title="Состояние" />
            <CardContent>
                {
                    tableValues ?
                        <SignalsTable data={tableValues}></SignalsTable> :
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            Нет данных
                        </Typography>
                }

            </CardContent>

        </Card>
    );
}


export default LocomotiveSignalsFrame;