import {combineReducers, configureStore } from "@reduxjs/toolkit";
import { cirstmApi } from "./cirstm/cirstm.api";
import { authApi } from "./cirstm/auth.api";
import { blockregApi } from "./cirstm/blockreg.api";
import userReducer from './user/userSlice'
import locomotiveReducer from './locomotive/locomotiveSlice'
//import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const rootReducer = combineReducers({
    userReducer,
    locomotiveReducer,
    [cirstmApi.reducerPath]: cirstmApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [blockregApi.reducerPath]: blockregApi.reducer,
})

export const store = configureStore({
    reducer: rootReducer,
    
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat([cirstmApi.middleware, authApi.middleware, blockregApi.middleware])
})

//setupListeners(store.dispatch)

//export type RootState = ReturnType<typeof store.getState>



//import {combineReducers, configureStore} from "@reduxjs/toolkit";
//import userReducer from './reducers/UserSlice'
//import {postAPI} from "../services/PostService";



// export const setupStore = () => {
//     return configureStore({
//         reducer: rootReducer,
//         middleware: (getDefaultMiddleware) =>
//             getDefaultMiddleware()
//                 .concat(postAPI.middleware)
//     })
// }

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch;

//export type AppStore = ReturnType<typeof setupStore>
//export type AppDispatch = AppStore['dispatch']