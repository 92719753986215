import { Grid, Typography } from '@mui/material';
import LocomotiveInfoFrame from './LocomotiveInfoFrame';
import LocomotiveSignalsFrame from './LocomotiveSignalsFrame';
import TelemetryFrame from './TelemetryFrame';


const LocomotiveStatus = () => {
    return (
        <Grid container spacing={1} >
            <LocomotiveInfoFrame/>
            <Grid item xs={12} sm={8} md={6} minWidth={200}>
                <LocomotiveSignalsFrame/>
            </Grid>
            <Grid item xs={12} sm={4} md={3} minWidth={200}>
                <TelemetryFrame></TelemetryFrame>
            </Grid>
        </Grid>);
}
 
export default LocomotiveStatus;