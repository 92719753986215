import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../models/models';

interface IUserState {
  user: IUser | null;
}

const initialState: IUserState = {
  user: null,
};

const readUser = () => {
  try{
    const user: IUser = JSON.parse(localStorage.getItem('current_user') ?? '')
    return user
  }
  catch{
    console.log('null user');
    return null
  }
}

const writeUser = (user: IUser|null) => {
  localStorage.setItem('current_user', JSON.stringify(user));
}

export const userSlice = createSlice({
  initialState: () => {
    return {...initialState, user: readUser()}
  },
  name: 'userSlice',
  reducers: {
    logout: () => {
      writeUser(null);
      initialState.user = null;
      //localStorage.removeItem('refresh_token')
      return initialState
    },
    setUser: (state, action: PayloadAction<IUser|null>) => {
      state.user = action.payload;
      writeUser(action.payload)
      //localStorage.setItem('refresh_token', state.user?.token.refresh_token ? state.user?.token.refresh_token : '')
    },
  },
});

export default userSlice.reducer;

export const { logout, setUser } = userSlice.actions;