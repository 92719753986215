import { useState, useEffect } from "react";
import { useLoginUserMutation } from '../../store/cirstm/auth.api'
import { setUser } from '../../store/user/userSlice'
import { useAppDispatch } from '../../hooks/redux';
import { getUserDevice } from '../../utils/utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Card, CardContent, Typography, TextField, FormControl, Button, FormHelperText } from "@mui/material";
import styles from './AuthForm.module.scss'
import { useNavigate } from 'react-router-dom'

interface IAuthFields {
    username: string
    password: string
    
}

export const AuthForm = () => {
    const [formData, setFormData] = useState<IAuthFields>({username: '', password: ''})

    const [loginUser, { isLoading, isError, error, isSuccess, data }] = useLoginUserMutation();
    const dispatch = useAppDispatch()

    const handleFormSubmit: SubmitHandler<IAuthFields> = (data: any) => {
        data = { ...data, userdevice: getUserDevice()}
        loginUser(data)
    }

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm<IAuthFields>()


    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess && data) {
            dispatch(setUser(data))
            navigate('/states')
        } else if (isError)
            console.log('error', data)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isError]);

    return(
        <form className={styles.layout} onSubmit={handleSubmit(handleFormSubmit)}>
        <Card className={styles.card} >

            <Typography variant="h4" sx={{margin: '1rem'}}>Вход</Typography>
            <CardContent className={styles.cardContent}>
                
                    <FormControl className={styles.formControl}>
                        <TextField
                        {...register('username', { required: "Введите логин!" })}
                        label="Логин"
                        variant="outlined"
                        className={styles.formField}>
                        </TextField>
                        {errors.username?.type === 'required' && <FormHelperText>{errors.username?.message}</FormHelperText>}
                    </FormControl>
                    
                    <FormControl className={styles.formControl}>
                        <TextField
                            {...register('password', { required: "Введите пароль!" })}
                        label="Пароль"
                        type="password"
                        className={styles.formField}>

                        </TextField>
                        {errors.password?.type === 'required' && <FormHelperText>{errors.password?.message}</FormHelperText>}
                    </FormControl>
                     
                    <Button type="submit" variant="outlined" className={styles.formField}>Войти</Button>   
               
            </CardContent>
          
        </Card>
        </form>
        
    )
}