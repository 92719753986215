import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { IDevice, IEvent } from '../../models/models';

interface ICurrentDevice {
  device: IDevice | null;
  event: IEvent | null;
}

const initialState: ICurrentDevice = {
  device: null,
  event: null
};

export const locomotiveSlice = createSlice({
  initialState,
  name: 'locomotiveSlice',
  reducers: {
    setDevice: (state, action: PayloadAction<IDevice|null>) => {
      state.device = action.payload;
    },
    setDeviceEvent: (state, action: PayloadAction<IEvent|null>) => {
      state.event = action.payload;
    },
  },
});

export default locomotiveSlice.reducer;

export const { setDevice, setDeviceEvent } = locomotiveSlice.actions;