import {useState, useEffect} from 'react'
import { useGetTelemetryListQuery, ITelemetryQuery } from "../../store/cirstm/cirstm.api";
import { useAppSelector } from '../../hooks/redux';
import { Card, CardContent, CardHeader, Typography, List } from '@mui/material';
import TelemetryItem from './TelemetryItem';
import CurrentSessionItem from './CurrentSessionItem';
import { ITelemetryRecord } from '../../models/models';
import Pagination from './Pagination';


const TelemetryFrame = () => {
    const { device } = useAppSelector((state: any) => state.locomotiveReducer)
    const [telemetryList, setTelemetryList] = useState<ITelemetryRecord[]>();
//    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const defaultQuery: ITelemetryQuery = { deviceId: device?.device_id || '', page: currentPage}
    const { data, isError, isLoading } = useGetTelemetryListQuery(defaultQuery, { refetchOnMountOrArgChange: true, skip: device?.device_id == null})
    
    const clearPage = () => {
        setTelemetryList([]);
        setTotalPages(0);
        setCurrentPage(1);
 //       setPage(1);
    };

    useEffect(()=> {
        if(!isError){
            if(!isLoading){
                if(data){
                    setTelemetryList(data.results);
                    setTotalPages(data.pages);
                    setCurrentPage(data.page);
                    //setPage(data.page_number);
                }
            }
        }else
            clearPage()
    }, [device, data, isError, isLoading])

    const changePage = (page: number) => {
        setCurrentPage(page);
    };

    return (
    <Card variant="outlined">
        <CardHeader title="Телеметрия" />
            {telemetryList ? (
                <CardContent>
                    <CurrentSessionItem/> 
                    <List>
                        {telemetryList.map((item) => {
                            return <TelemetryItem key={item.key} record={item}></TelemetryItem>;
                        })}
                    </List>
                    <Pagination page={currentPage} totalPages={totalPages} changePage={changePage} hasNext={data?.next != null} hasPrev={data?.previous != null}/>
                </CardContent>
            ) : (
                <CardContent>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Нет доступных записей
                    </Typography>
                </CardContent>
            )}
    </Card>);
}

export default TelemetryFrame;