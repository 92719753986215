import { createApi,} from '@reduxjs/toolkit/query/react';
import { IUser, IUserToken } from '../../models/models';
import { baseQuery } from './baseQuery';


export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery,

  endpoints: (builder) => ({
    loginUser: builder.mutation<IUser, {username: string, password: string, userdevice: string}>({
      query(data) {
        return {
          url: 'authentication/login/',
          method: 'POST',
          body: new URLSearchParams(data).toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        };
      },
      transformResponse: (response: any) => {
        const user = response.user
        const refresh = response.refresh_token
        if(user && refresh)
          return {...user, refresh_token: refresh}
        return null
      }
    }),

    refreshToken: builder.mutation<IUserToken,{refresh_token: string}>({
      query(data) {
        return {
          url: 'authentication/refresh',
          method: 'POST',
          body: new URLSearchParams(data).toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        };
      },
    }),
    logoutUser: builder.mutation<void, void>({
      query() {
        return {
          url: 'authentication/logout',
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRefreshTokenMutation,
  useLogoutUserMutation
} = authApi;
