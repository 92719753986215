import { createApi } from '@reduxjs/toolkit/query/react'
import { IEvent, IDevice, IListDeviceResponse, ITelemetryResponse } from '../../models/models'
import { baseQueryWithReauth } from './baseQuery'

export interface ITelemetryQuery{
    deviceId: string,
    page: number
}
export const cirstmApi = createApi({
    reducerPath: 'cirstm/api',
    baseQuery: baseQueryWithReauth,

    endpoints: build => ({
        listDevice: build.query<IDevice[], void>({
            query: () => ({
                url: 'locomotives/'
            }),
            transformResponse: (response: IDevice[]) => {
                return response.sort((lhs, rhs) => lhs['text_id'] < rhs['text_id'] ? -1 : 1)
            }
        }),

        getEvent: build.query<IEvent, string>({
            query: (deviceId) => ({
                url: `locomotives/${deviceId}/events/latest`
            }),
        }),

        getTelemetryList: build.query<ITelemetryResponse, ITelemetryQuery>({
            query: (queryArg) => ({
                url: `telemetry/list/${queryArg.deviceId}?page=${queryArg.page}`
            }),
            transformResponse: (response: ITelemetryResponse) => {
                console.log('tm response', response)
                return response
            }
        }),

        getCurrentTelemetry: build.query<any, string>({
            query: (device_id) => ({
                url: `telemetry/${device_id}/current`
            })
        }),

        getDownloadLink: build.query<string, string>({
            query: (item_key) => ({
                url: `telemetry/download?key=${item_key}`
            })
        })
    })
})

export const { 
    useListDeviceQuery,
    useGetEventQuery,
    useGetTelemetryListQuery,
    useLazyGetCurrentTelemetryQuery,
    useLazyGetDownloadLinkQuery
     } = cirstmApi