import LocomotiveStatus from '../components/locomotive-status/LocomotiveStatus'
import { useListDeviceQuery } from '../store/cirstm/cirstm.api'

export default function LocomotiveStatusPage() {
    const { isLoading, isError, data: devices } = useListDeviceQuery()

  return (
    <LocomotiveStatus/>
  )
}
