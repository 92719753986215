import { createApi } from '@reduxjs/toolkit/query/react';
import { IBlockModel, ITypesBook, IModuleModel, IComment, IBlocks, IPaginatedResponse } from '../../models/blockModel';
import { baseQueryWithReauth } from './baseQuery';


const SERVICE_PREFIX = 'blockreg'

const tags = ['Comment', 'Block', 'BlockList', 'Locations', 'Locomotives']



export const blockregApi = createApi({
  reducerPath: 'blockregApi',
  baseQuery: baseQueryWithReauth,

  tagTypes: tags,
  endpoints: (builder) => ({
    getTypes: builder.query<ITypesBook, void>({
            query: () => ({
                url: `${SERVICE_PREFIX}/types`,
                credentials: 'include'
            }),
            transformResponse: (response: any)=>{
                return {module_types: response['module-types'], block_states: response['block-states'], block_types: response['block-types']}
            }
        }),
    getBlocksList: builder.query<IPaginatedResponse<IBlockModel>, string>({
            query: (args) => ({
                url: `${SERVICE_PREFIX}/blocks${args}`,
                credentials: 'include'
            }),
            // transformResponse: (response: any) => {
            //   return response?.blocks ? response.blocks : null 
            // },
            providesTags: ['BlockList']
        }),
      getModulesList: builder.query<IModuleModel[], any>({
          query: (args) => ({
              url: `${SERVICE_PREFIX}get_modules_list` + (args ? '?' + args : ''),
              credentials: 'include'
          }),
          transformResponse: (response: any) => {
            return response?.modules ? response.modules : null 
          }
      }),
      getBlock: builder.query<IBlockModel, any>({
            query: (id) => ({
                url: `${SERVICE_PREFIX}/blocks/${id}`,
                credentials: 'include'
            }),
            providesTags: (result, error, id) => {
              if(id)
                return [{type: 'Block', id}, 'Comment']
              return ['Comment']
            } 
        }),
      getModule: builder.query<IModuleModel, any>({
          query: (args) => ({
              url: `${SERVICE_PREFIX}get_module` + (args ? '?' + args : ''),
              credentials: 'include'
          }),
          providesTags: ['Comment']
      }),

      updateBlock: builder.mutation<any, any>({
        query: (blockStatus) => ({
          url: `${SERVICE_PREFIX}update_block`,
          method: 'POST',
          body: {...blockStatus}
        }),
        invalidatesTags: (result, errors, blockStatus) => {
          let res: any = []
          if(blockStatus?.id)
            res.push({type: 'Block', id: blockStatus.block_id})
          if(blockStatus?.location)
            res.push("Locations")
          if(blockStatus?.locomotive)
            res.push("Locomotives")
          return [res]
        }
      }),

      addBlock: builder.mutation<any, IBlockModel>({
        query: (blockStatus) => ({
          url: `${SERVICE_PREFIX}add_block`,
          method: 'POST',
          body: {...blockStatus}
        }),
        invalidatesTags: ['BlockList']
      }),


      //Варианты для подстановки в соответствующие поля
      getLocations: builder.query<any, void>({
          query: () => ({
              url: `${SERVICE_PREFIX}/locations`,
              credentials: 'include'
          }),
          transformResponse: (response: any) => {
            if(response)
              return response.map((location: any) => {return {text: location[0]}})
          },
          providesTags: ['Locations']
      }),
      getLocomotives: builder.query<any, void>({
          query: () => ({
              url: `${SERVICE_PREFIX}/locomotives`,
              credentials: 'include'
          }),
          transformResponse: (response: any) => {
            if(response)
              return response.map((locomotive: any) => {return {text: locomotive[0]}})
          },
          providesTags: ['Locomotives']
      }),
      

      postComment: builder.mutation<any, IComment>({
        query: (comment) => ({
          url: `${SERVICE_PREFIX}comments`,
          method: 'POST',
          body: {...comment}
        }),
        invalidatesTags: ['Comment']
      }),

      deleteComment: builder.mutation<any, string>({
        query: (commentId) => ({
          url: `${SERVICE_PREFIX}comments?id=${commentId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Comment']
      }),

      getComments: builder.query<IComment[], any>({
        query: (args) => ({
          url: `${SERVICE_PREFIX}comments?${args ?? ''}`,
          credentials: 'include'
        }),
        providesTags: ['Comment']
      })
  }),
});

export const {
  useGetTypesQuery,
  useGetBlocksListQuery,
  useGetModulesListQuery,
  usePostCommentMutation,
  useGetCommentsQuery,
  useDeleteCommentMutation,
  useGetBlockQuery,
  useGetModuleQuery,
  useUpdateBlockMutation,
  useGetLocationsQuery,
  useGetLocomotivesQuery,
  useAddBlockMutation,
} = blockregApi;
