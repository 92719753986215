import Avatar from '@mui/material/Avatar';

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    const splitName = (name: string) => {
        name = name.toUpperCase()
        if (name.length < 2)
            return name
        if (name.split(' ').length >= 2)
            return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
        return name.substring(0, 2)
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: splitName(name),
    };
}


export default function BackgroundLetterAvatars(props: any) {
    const {name, ...other} = props
    return (
        <Avatar {...stringAvatar(name ?? '')} {...other}/>
    );
}
