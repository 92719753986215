import Header from './header/Header'
import styles from './Layout.module.scss'

type Props = {
    children: any
}

const Layout = ({ children }: Props) => {
  return (
		<div className='block m-2'>
          <Header />
					{ <div className={styles.wrapper}>{children}</div> }
      {/* <div>{children}</div> */}
    </div>
  )
}

export default Layout